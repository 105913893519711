import { OrderByPipe } from 'ngx-pipes';
import { CONSTANTS } from 'src/environments/constants';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material/select';
import { DateAdapter } from '@angular/material/core';

import { DirectoryAggregationsStoreService } from './../../../services/directory-aggregations-store/directory-aggregations-store.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryFiltersStoreService } from './../../../services/directory-filters-store/directory-filters-store.service';

import { DirectoryAppliedFilter } from 'src/app/directory/models/directory-applied-filter.model';
import { DirectoryFieldForFilter } from 'src/app/directory/models/directory-config.model';
import { Field, FieldType } from 'src/app/directory/models/fields/field.model';
import { LinkedChoiceField } from 'src/app/directory/models/fields/linked-choice-field.model';

@Component({
  selector: 'app-directory-filter',
  templateUrl: './directory-filter.component.html',
  styleUrls: ['./directory-filter.component.scss'],
})
export class DirectoryFilterComponent implements OnInit, OnDestroy {
  public CONSTANTS = CONSTANTS;
  public filterForm: FormGroup;
  private subscriptions: Subscription[] = [];

  public listPossibilities: string[];
  public currentLang: string;
  public field: Field;
  public booleanNot: boolean = false;

  public allFields: Field[] = [];
  public constraintOperators = [];
  public criteriaUsedOperator: string = null;

  @Input() filter: DirectoryFieldForFilter;
  @Input() criteria: any;
  @Input() isSmall: boolean = false;

  constructor(
    private fb: FormBuilder,
    private directoryFiltersStore: DirectoryFiltersStoreService,
    private directoryStore: DirectorySharedDataService,
    private translateService: TranslateService,
    private aggregationsStore: DirectoryAggregationsStoreService,
    private orderByPipe: OrderByPipe,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale(this.translateService.currentLang);
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;

    if (this.isList()) {
      let valuesList$ = this.directoryStore.getAvailableFields()
      .pipe(
        map((fields) => {
          this.allFields = fields;
          this.field = fields.find(x => x.field_id === this.filter.code);
          return this.directoryFiltersStore.getListIdFromFieldId(fields, this.filter.code);
        }),
        filter(x => {
          if(x) return true
          else return false
        }),
        switchMap((listId) => {
          if(listId) {
            return this.directoryStore.findList(listId)
          }
        }),
        map((list:any) => {
          let elementsList = list.elements;
          if(this.filter.availableValues) {
            let filteredList = elementsList.filter(
              x => this.filter.availableValues.map(availableValue => availableValue.key).includes(x.element_id) // Only keep list element if found in availableValue
            );
            return filteredList;
          }
          if(this.filter.type === 'LinkedChoice' && !(this.field as LinkedChoiceField).parent_field_id) {
            let filteredList = elementsList.filter(
              x => !x.core_hasParentValue // Only keep list element if found in availableValue
            );
            return filteredList;
          }
          return elementsList;
        })
      )

      let aggregations$ = this.aggregationsStore.getAppliedAggregations();

      combineLatest([valuesList$, aggregations$])
      .subscribe(([valuesList, aggregations]) => {
        if(aggregations.hasOwnProperty(this.filter.code)) {
          this.filter.aggregation = aggregations[this.filter.code].buckets;
          valuesList.forEach(element => {
            element.isDisabled = !this.filter.aggregation.map(x => x.key).includes(element.element_id);
            if(!element.isDisabled) {
              element.count = this.filter.aggregation.find(x => x.key === element.element_id).count;
            }
            else {
              element.count = null;
            }
          });
        }

        const result = this.orderList(valuesList);
        this.listPossibilities = result[0];
        // orderByPipe only if not hierarchical valuesList (!result[1])
        if(aggregations.hasOwnProperty(this.filter.code) && !result[1]) {
          this.listPossibilities = this.orderByPipe.transform(this.listPossibilities, ['isDisabled', '-count'])
        }
      });
    }
// hum ... means 1 request by setted filter
    this.subscriptions.push(this.directoryFiltersStore
      .getAppliedFilters()
      .pipe(
        take(1),
        tap((appliedFilters) => {
          this.initFilter(appliedFilters);
        })
      )
      .subscribe());
  }

  public orderList(elements) {
    let hashArr = {};
    let isHierarchical = false;
    hashArr['undefined'] = [];
    for (const element of elements) {
      if ('core_hasParentValue' in element) {
         if (!(element.core_hasParentValue.element_id in hashArr)) hashArr[element.core_hasParentValue.element_id] = [];
         hashArr[element.core_hasParentValue.element_id].push(element);
         isHierarchical = true;
      } else {
         hashArr['undefined'].push(element);
      }
    }
    let result = this.hierarchySort(hashArr, 'undefined', [], 0);

    elements = result;
    return [elements, isHierarchical];
  }

  public hierarchySortFunc(a,b ) {
    return a.core_hasOrder - b.core_hasOrder;
  }

  public hierarchySort(hashArr, key, result, depth) {
    if (!(key in hashArr)) return;
    let arr = hashArr[key].sort(this.hierarchySortFunc);
    for (const element of arr) {
      element.depth = depth;
      result.push(element);
      this.hierarchySort(hashArr, element.element_id, result, depth+1);
    }
    return result;
  }

  private initFilter(appliedFilters: DirectoryAppliedFilter[]) {
    this.constraintOperators = Object.keys(ConstraintOperators).map(k => ConstraintOperators[k as any]);

    // If the URL has criteria and has the header bar,
    // we'll initialize the selected filters with their predefined values
    if (this.criteria != null || this.criteria != undefined) {
      this.initWithCriteria();
    } else {
      const filterInAppliedFilters = appliedFilters.find(
        (appliedFilter) => appliedFilter.concernedField === this.filter.id
      );

      if (filterInAppliedFilters) {
        this.filterForm = this.fb.group({
          filter: new FormControl(filterInAppliedFilters.value),
          operator: new FormControl()
        });
      } else {
        this.filterForm = this.fb.group({
          filter: new FormControl(),
          operator: new FormControl()
        });
      }
      this.criteriaUsedOperator = this.genericOperatorForCriteria(this.filter.type);
    }    
    this.subscribeForm();
  }

  private initWithCriteria() {
    let criteriaValue: any = null;

    if (this.isList() && Array.isArray(this.criteria)) {
      let tmpArray = [];
      this.criteria.forEach((x) => {
        // Second item has the value
        let tmpString = x.split(/\s(.*)/).filter(x => x)[1];
        // Remove ''
        tmpString = tmpString.substring(1, tmpString.length - 1);
        tmpArray.push(tmpString);
      });

      // Constraint operator
      this.criteriaUsedOperator = this.genericOperatorForCriteria(this.filter.type);
      if (this.filter.type === FieldType.LinkedChoice || this.filter.type === FieldType.UniqueChoiceList) {
        criteriaValue = tmpArray[0].toString();
      } else {
        criteriaValue = tmpArray;
      }
    } else {
      let criteriaOperatorWithValue = this.criteria.split(/\s(.*)/).filter(x => x);

      // Constraint operator
      this.criteriaUsedOperator = criteriaOperatorWithValue[0];
      // Value
      criteriaValue = criteriaOperatorWithValue[1].toString();
    }

    // Prepare values:
      // Remove ''
    if (criteriaValue.includes("\'")) {
      criteriaValue = criteriaValue.substring(1, criteriaValue.length - 1);
    }
      // Boolean type -> convert string to boolean
    if (this.filter.type === FieldType.Boolean) {
      if (criteriaValue.toLowerCase() === "yes")
        criteriaValue = (/yes/i).test(criteriaValue.toLowerCase());
      else if (criteriaValue.toLowerCase() === "true")
        criteriaValue = (/true/i).test(criteriaValue.toLowerCase());
      else {
        criteriaValue = false;
        this.booleanNot = true;
      }
    }
      // Date type -> convert from milliseconds to selected date (only for datepicker input)
    let numToDate = null;
    if (this.filter.type === FieldType.Date) {
      numToDate = new Date(parseInt(criteriaValue, 10));
    }

    // Fix when MCL types with only 1 value (it needs to be an array)
    if (this.isList() &&
        (this.filter.type != FieldType.LinkedChoice && this.filter.type != FieldType.UniqueChoiceList) &&
        !Array.isArray(criteriaValue)) {
      criteriaValue = [criteriaValue];
    }

    // Init inputs with criteria values
    this.filterForm = this.fb.group({
      filter: new FormControl(numToDate != null ? numToDate : criteriaValue),
      operator: new FormControl(this.criteriaUsedOperator != null ? this.criteriaUsedOperator : null)
    });

    // Init results list with init values
    this.updateFiltersDependingOnType(criteriaValue);
  }

  private subscribeForm() {
    this.subscriptions.push(this.filterForm.get('filter')
      .valueChanges.pipe(
        //debounceTime(600),
        filter((val) => this.filterForm.get('filter').valid),
        tap((valueFromFilter: any) => {
          if (valueFromFilter === true) this.booleanNot = false;

          // Change linked 'operator' select (if any) when a value has been selected
          if ((this.filterForm.get('operator').value === null) && this.hasValue()) {
            this.filterForm.get('operator').setValue('equalTo');
          }

          this.updateFiltersDependingOnType(valueFromFilter);
        })
      )
      .subscribe());
  }

  private updateFiltersDependingOnType(valueFromFilter) {
    const numRegex = /(^(?:\d*)$)/;

    // Prepare value for Date types (convert to milliseconds + to string)
    if (this.filter.type === FieldType.Date &&
        valueFromFilter != null &&
        !(numRegex.test(valueFromFilter))) { // With criteria, value has already been formatted
      valueFromFilter = Date.parse(valueFromFilter).toString();
    }

    if (typeof valueFromFilter === 'string' ||
        valueFromFilter instanceof String) {
      if (valueFromFilter.length > 0) {
        this.updateAppliedFilters([valueFromFilter as string]);
      } else {
        this.updateAppliedFilters([]); // remove the filter as empty searches return an error
      }
    } else if (typeof valueFromFilter === 'boolean') {
      if (valueFromFilter === true || valueFromFilter === false)
        this.updateAppliedFilters([valueFromFilter as any]);
      else
        this.updateAppliedFilters([]); // remove the filter
    } else {
      this.updateAppliedFilters(valueFromFilter);
    }
  }

  private updateAppliedFilters(expectedValues: any[]) {
    const currentFilter: DirectoryAppliedFilter = {
      concernedField: this.filter.id,
      type: this.filter.type,
      operator: this.criteriaUsedOperator,
      value: expectedValues,
      fieldCode: this.filter.code,
      viaFieldId: this.filter.viaFieldId
    };

    if (!expectedValues || expectedValues.length === 0) {
      this.directoryFiltersStore.removeFilter(currentFilter);
    } else {
      this.directoryFiltersStore.updateFilter(currentFilter);
    }
  }

  // Generic operator selector for all types
  genericOperatorForCriteria(fieldType: string): string {
    let equalToArray: string[] = [
      FieldType.TripleState,
      FieldType.Boolean,
      FieldType.UniqueChoiceList,
      FieldType.LinkedChoice,
      FieldType.Numeric,
      FieldType.Float,
      FieldType.Date,
    ]
    if (equalToArray.includes(fieldType)) {
      return 'equalTo';
    }
    else return 'contains';
  }

  operatorChanged(event: MatSelectChange) {
    // An operator has been selected
    this.criteriaUsedOperator = event.value;
    this.updateFiltersDependingOnType(this.filterForm.get('filter').value);
  }

  public resetField(event) {
    this.filterForm.get('filter').reset();
    this.filterForm.get('operator').reset();
    event.stopPropagation();
  }

  public hasValue(): boolean {
    if (this.filterForm.get('filter').value != null) {
      return true;
    }
    return false;
  }

  public isList() {
    if (
      this.filter.type === 'MultipleChoiceList' ||
      this.filter.type === 'UniqueChoiceList' ||
      this.filter.type === 'MultipleChoiceListWithComment' ||
      this.filter.type === 'OrderedMultipleChoiceList' ||
      this.filter.type === 'LinkedChoice' ||
      this.filter.type === 'RevertedFromElement'
    ) {
      return true;
    }
    return false;
  }

  public isMultiple() {
    if (
      this.filter.type === 'MultipleChoiceList' ||
      this.filter.type === 'MultipleChoiceListWithComment' ||
      this.filter.type === 'OrderedMultipleChoiceList' ||
      this.filter.type === 'RevertedFromElement'
    ) {
      return true;
    }
    return false;
  }

  public isDisabled(elementList: any) {
    if (this.filter.aggregation) {
      if(this.filter.aggregation.map(x => x.key).includes(elementList.element_id)) {
        return false;
      }
    }
    return true;
  }

  public translationCompatibilityForOlderWidget(objectOrString, lang): {} {
    if(typeof objectOrString !== 'object') {
      let tempValue = objectOrString
      objectOrString = {};
      objectOrString[lang] = tempValue;
    }
    return objectOrString[lang];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

export enum ConstraintOperators {
  EQUAL = 'equalTo',
  GREATER_THAN = 'greaterThan',
  GREATER_THAN_OR_EQUAL = 'greaterThanOrEqualTo',
  LESS_THAN = 'lessThan',
  LESS_THAN_OR_EQUAL = 'lessThanOrEqualTo',
  NOT_EQUAL = 'notEqualTo'
}
