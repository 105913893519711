<aside class="directory-secondary-sheet-wrapper">
  <header 
      class="directory-secondary-sheet-navigation util-el-surface" 
      [style.backgroundColor]="widgetConfig?.style.mainColor + '14'">
    <nav>
      <button mat-button 
          appearance="with-icon" 
          (click)="closeSecondDialog()"
          class="directory-secondary-sheet-back">
        <mat-icon>arrow_back</mat-icon>
        <span class="button1">{{ 'directory.sheet.back' | translate }}</span>
      </button>
      <div class="flex-spacer"></div>
      <button mat-icon-button
          *ngIf="widgetConfig?.requests?.hasModificationsRequests"
          class="mr-2"
          (click)="closeAndEditSecondDialog()">
        <mat-icon>edit</mat-icon>
      </button>
    </nav>
  </header>

  <div class="directory-secondary-sheet-core">
    <app-directory-entity 
      [entityId]="currentOpenedElementId" 
      [forceEntity]="true" 
      [widgetConfig]="widgetConfig" 
      *ngIf="!range"></app-directory-entity>
    <app-directory-adb 
      [range]="range" 
      [id]="currentOpenedElementId" 
      [config]="widgetConfig" 
      *ngIf="range"></app-directory-adb>
  </div>
</aside>
