import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { DirectoryConfig } from "src/app/directory/models/directory-config.model";
import { DirectoryAddRequestService } from "src/app/directory/services/directory-add-request/directory-add-request.service";
import { DirectoryAddSubrequestDialogComponent } from "../../dialog/directory-add-subrequest-dialog/directory-add-subrequest-dialog.component";
import { FieldType } from "src/app/directory/models/fields/field.model";
import { DirectoryEmbedRequestService } from "src/app/directory/services/directory-embed-request/directory-embed-request.service";

@Component({
    selector: 'app-add-subrequest-button',
    templateUrl: './add-subrequest-button.component.html',
    styleUrls: ['./add-subrequest-button.component.scss']
})
export class AddSubrequestButton {
    @Input() config: DirectoryConfig;
    @Input() adbId: string;
    @Input() field: any;
    @Output() subrequestElement = new EventEmitter();

    constructor(
        private dialog: MatDialog,
        public directoryAddRequestService: DirectoryAddRequestService,
        public directoryEmbedRequestService: DirectoryEmbedRequestService,
    ){}

    openAdbSubrequest() {
        if (this.field.type === FieldType.UniqueEntityLink || this.field.type === FieldType.MultipleEntityLink)
            this.adbId = "entityForm"; // Form for Entity types

        const dialogRef = this.dialog.open(DirectoryAddSubrequestDialogComponent, {
            width: '1140px',
            maxWidth: '100vw',
            maxHeight: '90vh',
            data: {
                config: this.config,
                adbId: this.adbId,
                communityRestriction: this.field.communityRestriction,
                fieldType: this.field.type
            },
            autoFocus: false,
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res && res[0] === "success") {
                const currentCounter = this.directoryEmbedRequestService.getSubrequestsCounter();
                // Create new element object to work with
                let newAdbElement: any = {};
                if (this.field.type === FieldType.UniqueEntityLink || this.field.type === FieldType.MultipleEntityLink) {
                    newAdbElement = {
                        entity_id: "_:" + currentCounter,
                        '6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasName': this.directoryAddRequestService.subrequestForm.value['6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasName'],
                        selectedCommunity: res[1],
                        values: this.directoryAddRequestService.subrequestForm.value
                    }
                } else {
                    newAdbElement = {
                        element_id: "_:" + currentCounter,
                        core_hasListName: this.directoryAddRequestService.subrequestForm.value['core_hasListName'],
                        core_hasOrder: 0,
                        depth: 0,
                        values: this.directoryAddRequestService.subrequestForm.value
                    };
                }

                this.subrequestElement.emit(newAdbElement);
            }
        });
    }
}