import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { concatMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { DirectoryResultsStoreService } from 'src/app/directory/services/directory-results-store/directory-results-store.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';

import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';
import { OpenedElement } from './../../../models/directory-opened-element.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-directory-secondary-sheet',
  templateUrl: './directory-secondary-sheet.component.html',
  styleUrls: ['./directory-secondary-sheet.component.scss']
})
export class DirectorySecondarySheetComponent implements OnInit {
  public widgetConfig: DirectoryConfig;
  public widgetId: string;
  public widgetLang: string;
  public currentOpenedElementId: string;
  public range: string;
  public subscriptionSecondLevel: Subscription;

  @Input() fullSize: boolean = false;
  @Output() closeSecondSheet = new EventEmitter<boolean>();
  @Output() closeAndEditSecondSheet = new EventEmitter<boolean>();
  
  constructor(
    private directoryData: DirectorySharedDataService,
    private resultsStore: DirectoryResultsStoreService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.widgetId = this.directoryData.widgetId;
    this.widgetLang = this.directoryData.widgetLang;

    this.subscriptionSecondLevel = this.directoryData.getDirectoryConfig().pipe(
      concatMap((config: DirectoryConfig) => {
        this.widgetConfig = config;
        // console.log("widgetConfigEntity2Sheet",config)
        return this.resultsStore.getOpenedElement();
      }),
    ).subscribe((openedElement: OpenedElement) => {
      if (openedElement && !openedElement.isMain) {
        this.currentOpenedElementId = openedElement.id;
        this.range = openedElement.range;
      }
    });
  }

  closeSecondDialog() {
    this.closeSecondSheet.emit(true);
    this.subscriptionSecondLevel.unsubscribe();
  }

  closeAndEditSecondDialog() {
    this.router.navigate(["/"+this.widgetLang+"/annuaire/"+this.widgetId+"/entity/"+this.currentOpenedElementId+"/modifications"+"/"+this.range]);
    if (this.fullSize) this.closeAndEditSecondSheet.emit(true);
  }
}
