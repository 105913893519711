import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectoryRoutingModule } from './directory-routing.module';
import { SharedModule } from '../shared/shared.module';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';

import { DirectoryComponent } from './components/directory/directory.component';
import { DirectoryHeaderComponent } from './components/directory-header/directory-header.component';
import { DirectoryFiltersComponent } from './components/directory-filters/directory-filters.component';
import { DirectoryListComponent } from './components/directory-list/directory-list.component';
import { DirectoryMapComponent } from './components/directory-map/directory-map.component';
import { DirectorySummaryComponent } from './components/directory-summary/directory-summary.component';
import { DirectoryMarkerPreviewComponent } from './components/directory-marker-preview/directory-marker-preview.component';
import { DirectoryEntitySheetComponent } from './components/directory-entity-sheet/directory-entity-sheet.component';
import { DirectoryEntityComponent } from './components/directory-entity/directory-entity.component';
import { DirectoryEntityFieldComponent } from './components/directory-entity-field/directory-entity-field.component';
import { CustomizedFontColorDirective } from './directives/customized-font-color/customized-font-color.directive';
import { CustomizedHoverColorDirective } from './directives/customized-hover-color/customized-hover-color.directive';
import { DirectoryDataSourceComponent } from './components/directory-data-source/directory-data-source.component';
import { DirectoryLegendComponent } from './components/directory-legend/directory-legend.component';
import { DirectoryLegendDropdownComponent } from './components/directory-legend-dropdown/directory-legend-dropdown.component';
import { OverlayContainer, FullscreenOverlayContainer } from '@angular/cdk/overlay';
import { DirectoryAllFiltersComponent } from './components/directory-filters/directory-all-filters/directory-all-filters.component';
import { DirectoryFilterComponent } from './components/directory-filters/directory-filter/directory-filter.component';
import { DirectoryApplyFiltersPipe } from './pipes/directory-apply-filters/directory-apply-filters.pipe';
import { NgPipesModule, OrderByImpurePipe, OrderByPipe, TailPipe } from 'ngx-pipes';
import { DirectoryEntityPageComponent } from './pages/directory-entity-page/directory-entity-page.component';
import { DirectoryFieldForRequestDialogComponent } from './components/directory-field-for-request-dialog/directory-field-for-request-dialog.component';
import { DirectoryAskLeaveDialogComponent } from './components/dialog/directory-ask-leave-dialog/directory-ask-leave-dialog.component';
import { DirectoryModificationsRequestValidationComponent } from './components/dialog/directory-modifications-request-validation/directory-modifications-request-validation.component';
import { DirectoryEntityDialogComponent } from './components/dialog/directory-entity-dialog/directory-entity-dialog.component';
import { DirectoryEntityContactDialogComponent } from './components/dialog/directory-entity-contact-dialog/directory-entity-contact-dialog.component';
import { DirectoryEntityCreationDialogComponent } from './components/dialog/directory-entity-creation-dialog/directory-entity-creation-dialog.component';
import { HandleMainFiltersDirective } from './directives/handle-main-filters/handle-main-filters.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { EntityLinkComponent } from './components/fields/entity-link/entity-link.component';
import { EntityAdbElementComponent } from './components/fields/entity-adb-element/entity-adb-element.component';
import { EntityAdbElementDetailsComponent } from './components/fields/entity-adb-element-details/entity-adb-element-details.component';
import { DirectoryAddRequestComponent } from './pages/directory-add-request/directory-add-request.component';
import { DirectoryCommunitySelectComponent } from './components/directory-community-select/directory-community-select.component';
import { DirectoryAddRequestFormComponent } from './components/directory-add-request-form/directory-add-request-form.component';
import { AddFieldComponent } from './components/fields/add-field/add-field.component';
import { AddFieldTextComponent } from './components/fields/add-field/add-field-text/add-field-text.component';
import { AddFieldListComponent } from './components/fields/add-field/add-field-list/add-field-list.component';
import { AddFieldImageComponent } from './components/fields/add-field/add-field-image/add-field-image.component';
import { AddFieldBooleanComponent } from './components/fields/add-field/add-field-boolean/add-field-boolean.component';
import { AddFieldDateComponent } from './components/fields/add-field/add-field-date/add-field-date.component';
import { AddFieldLinkedChoiceComponent } from './components/fields/add-field/add-field-linked-choice/add-field-linked-choice.component';
import { AddSubrequestButton } from './components/fields/add-subrequest-button/add-subrequest-button.component';
import { DirectoryAddRequestValidationDialogComponent } from './components/dialog/directory-add-request-validation-dialog/directory-add-request-validation-dialog.component';
import { DirectoryVisibilityRequestValidationDialogComponent } from './components/dialog/directory-visibility-request-validation-dialog/directory-visibility-request-validation-dialog.component';
import { AddFieldEntityLinkComponent } from './components/fields/add-field/add-field-entity-link/add-field-entity-link.component';
import { EditFieldEntityLinkComponent } from './components/fields/entity-link/edit-field-entity-link/edit-field-entity-link.component'
import { FindEntityDialogComponent } from './components/dialog/find-entity-dialog/find-entity-dialog.component';
import { EntityCardComponent } from './components/entity-card/entity-card.component';
import { EntityDetailsCardComponent } from './components/fields/entity-details-card/entity-details-card.component';
import { DirectorySecondarySheetComponent } from './components/directory/directory-secondary-sheet/directory-secondary-sheet.component';
import { DirectoryAdbComponent } from './components/directory-adb/directory-adb.component';
import { LinkedChoiceComponent } from './components/fields/linked-choice/linked-choice.component';
import { DirectoryFieldsRequestComponent } from './components/directory-add-request-form/directory-fields-request/directory-fields-request.component';
import { DirectoryAddSubrequestDialogComponent } from './components/dialog/directory-add-subrequest-dialog/directory-add-subrequest-dialog.component';

@NgModule({
  declarations: [
    DirectoryComponent,
    DirectoryHeaderComponent,
    DirectoryFiltersComponent,
    DirectoryListComponent,
    DirectoryMapComponent,
    DirectorySummaryComponent,
    DirectoryMarkerPreviewComponent,
    DirectoryEntitySheetComponent,
    DirectoryEntityComponent,
    DirectoryEntityFieldComponent,
    CustomizedFontColorDirective,
    CustomizedHoverColorDirective,
    DirectoryDataSourceComponent,
    DirectoryLegendComponent,
    DirectoryLegendDropdownComponent,
    DirectoryAllFiltersComponent,
    DirectoryFilterComponent,
    DirectoryApplyFiltersPipe,
    DirectoryEntityPageComponent,
    DirectoryFieldForRequestDialogComponent,
    DirectoryAskLeaveDialogComponent,
    DirectoryModificationsRequestValidationComponent,
    DirectoryEntityDialogComponent,
    DirectoryEntityContactDialogComponent,
    DirectoryEntityCreationDialogComponent,
    HandleMainFiltersDirective,
    EntityLinkComponent,
    EntityAdbElementComponent,
    EntityAdbElementDetailsComponent,
    DirectoryAddRequestComponent,
    DirectoryCommunitySelectComponent,
    DirectoryAddRequestFormComponent,
    AddFieldComponent,
    AddFieldTextComponent,
    AddFieldListComponent,
    AddFieldImageComponent,
    AddFieldBooleanComponent,
    AddFieldDateComponent,
    AddFieldLinkedChoiceComponent,
    AddFieldEntityLinkComponent,
    EditFieldEntityLinkComponent,
    AddSubrequestButton,
    DirectoryAddRequestValidationDialogComponent,
    DirectoryVisibilityRequestValidationDialogComponent,
    FindEntityDialogComponent,
    EntityCardComponent,
    EntityDetailsCardComponent,
    DirectorySecondarySheetComponent,
    DirectoryAdbComponent,
    LinkedChoiceComponent,
    DirectoryFieldsRequestComponent,
    DirectoryAddSubrequestDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    NgPipesModule,
    ScrollingModule,
    DirectoryRoutingModule,
  ],
  entryComponents: [
    DirectoryMarkerPreviewComponent,
    DirectoryDataSourceComponent,
    DirectoryAllFiltersComponent,
    DirectoryFieldForRequestDialogComponent,
    DirectoryAskLeaveDialogComponent,
    DirectoryModificationsRequestValidationComponent,
    DirectoryEntityDialogComponent,
    DirectoryEntityContactDialogComponent,
    DirectoryEntityCreationDialogComponent,
    EntityAdbElementDetailsComponent,
    DirectoryAddRequestValidationDialogComponent,
    FindEntityDialogComponent,
    DirectoryAddSubrequestDialogComponent
  ],
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    DirectoryApplyFiltersPipe,
    TailPipe,
    OrderByPipe,
    OrderByImpurePipe,
  ],
})
export class DirectoryModule {}
