import { DirectoryConfig } from './../../../models/directory-config.model';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { concatMap } from 'rxjs/operators';
import { DirectoryResultsStoreService } from 'src/app/directory/services/directory-results-store/directory-results-store.service';
import { CONSTANTS } from 'src/environments/constants';
import { DirectorySearchService } from './../../../services/directory-search/directory-search.service';
import { DirectorySharedDataService } from './../../../services/directory-shared-data.service';

@Component({
  selector: 'app-entity-link',
  templateUrl: './entity-link.component.html',
  styleUrls: ['./entity-link.component.scss']
})
export class EntityLinkComponent implements AfterViewInit, OnChanges {
  @Input() id: string;
  public entityName: string;
  public widgetConfig: DirectoryConfig;

  constructor(
    private directorySearch: DirectorySearchService,
    private directoryStore: DirectorySharedDataService,
    private directoryResults: DirectoryResultsStoreService
  ) {}

  ngAfterViewInit(): void {
    if (this.id) {
      this.directoryStore.getDirectoryConfig().pipe(
        concatMap((config: DirectoryConfig) => {
          this.widgetConfig = config;
          return this.directorySearch.findEntityData(this.id, true);
        }),
      ).subscribe((entity) => {
        this.entityName = entity[CONSTANTS.mainName];
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let oldId = changes.id.previousValue;
    let currentId = changes.id.currentValue;

    if (oldId != currentId) {
      this.directorySearch.findEntityData(currentId, true).subscribe((entity) => {
        this.entityName = entity[CONSTANTS.mainName];
      });
    }
  }

  changeFocus(id) {
    this.directoryResults.setOpenedElement({
      id: id,
      range: null,
      isMain: this.widgetConfig.isFocusOnAssociatedDB && this.widgetConfig.concernedAssociatedDB ? false : true
    });
  }

}
