import { CONSTANTS } from 'src/environments/constants';
import { FieldType, FieldTypes, isList, ExternalFieldTypes } from '../../models/fields/field.model';
import { ModificationsValue } from './../../models/directory-modifications-request.model';
import { DirectoryField, DirectoryFieldForSheet } from './../../models/directory-config.model';
import { Component, Input, OnChanges } from '@angular/core';
import { DirectoryModificationsRequestService } from '../../services/directory-modifications-request/directory-modifications-request.service';
import { MatDialog } from '@angular/material/dialog';
import { DirectoryFieldForRequestDialogComponent } from '../directory-field-for-request-dialog/directory-field-for-request-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-directory-entity-field',
  templateUrl: './directory-entity-field.component.html',
  styleUrls: ['./directory-entity-field.component.scss'],
})
export class DirectoryEntityFieldComponent implements OnChanges {
  public CONSTANTS = CONSTANTS;
  public fieldTypes = FieldTypes;
  public isListFromModel = isList;
  public currentLang: string;
  private editableReadOnlyFields: string[] = [FieldType.ExternalSourceAssociatedDb];

  @Input() fieldValue: any;
  @Input() field: DirectoryFieldForSheet;
  @Input() fieldList: any;
  @Input() entity: any;
  @Input() entityId: string;
  @Input() isEditable: boolean;

  constructor(
    private directoryModificationsRequestService: DirectoryModificationsRequestService,
    public dialog: MatDialog,
    public translateService: TranslateService
  ) {}

  ngOnChanges() {
    this.currentLang = this.translateService.currentLang;

    if (this.field && this.fieldValue) {
      if (this.field.range) this.field.isAdb = true;
      else this.field.isAdb = false;
      if (this.fieldValue && this.isList() && this.fieldValue.length > 1) {
        var hashArr = {};
        hashArr['undefined'] = [];
        for (var i=0; i < this.fieldValue.length; i++) {
          if ('core_hasParentValue' in this.fieldValue[i]) {
            if (!(this.fieldValue[i].core_hasParentValue.element_id in hashArr)) hashArr[this.fieldValue[i].core_hasParentValue.element_id] = [];
            hashArr[this.fieldValue[i].core_hasParentValue.element_id].push(this.fieldValue[i]);
          } else {
            hashArr['undefined'].push(this.fieldValue[i]);
          }
        }
        var result = this.hierarchySort(hashArr, 'undefined', [], 0);
        this.fieldValue = result;
      }
    }

    if (this.isList()) {
      // Fetch list from ID and get the correct value in
    }
  }

  public hierarchySortFunc(a, b) {
    return a.core_hasOrder - b.core_hasOrder;
  }

  public  hierarchySort(hashArr, key, result, depth) {
    if (!(key in hashArr)) return;
    var arr = hashArr[key].sort(this.hierarchySortFunc);
    for (var i=0; i < arr.length; i++) {
      arr[i].depth = depth;
      result.push(arr[i]);
      this.hierarchySort(hashArr, arr[i].element_id, result, depth+1);
    }

    return result;
  }

  public orderList(a, b) : number {
//   console.log(a,b);
    if ('core_hasParentValue' in a && b.element_id == a.core_hasParentValue) return 1;
    if ('core_hasParentValue' in b && a.element_id == b.core_hasParentValue) return -1;
    if (!('core_hasParentValue' in a) && !('core_hasParentValue' in b)) return a.core_hasOrder - b.core_hasOrder;
    if ('core_hasParentValue' in a && 'core_hasParentValue' in b && a.core_hasParentValue == b.core_hasParentValue) return a.core_hasOrder - b.core_hasOrder;
    return 0;
//     if (a.core_hasParentValue & b.core_hasParentValue && a.core_hasParentValue == b.core_hasParentValue) return a.core_hasOrder - b.core_hasOrder;
//     if (a.core_hasParentValue & b.core_hasParentValue && a.core_hasParentValue !== b.core_hasParentValue) return a.core_hasOrder - b.core_hasOrder;
  }

  public isList(): boolean {
    if (this.field) {
       return this.isListFromModel(this.field.type);
    }
    return false;
  }

  public isMultiple(): boolean { //--> To check. Is this method used?
    if (this.field.type === 'MultipleChoiceList') {
      return true;
    }
    return false;
  }

  public isEditionModeActive() {
    if (this.isEditable) { return false }
    return this.directoryModificationsRequestService.isEditionModeActive;
  }

  public isCurrentDomainField() {
    // console.log(this.field.domain, this.field.id,this.field.range)
    if (this.field) {
      return this.directoryModificationsRequestService.isCurrentDomainField(this.field.id,this.field.domain);
    }
    return false;
  }

  public showField(): boolean {
    if (this.fieldHasValue()) return true;
    if (this.isEditionModeActive() && this.isCurrentDomainField()) return true;
    if (this.isEditionModeActive() && !this.isCurrentDomainField()) return false;
    if (this.showTitle() && this.field.showFieldWhenNoValue) {
      return true;
    }
    return false;
  }

  /**
   * Some read only field may LOOK editable and will create suggestion notifications instead of edit notifications
   */
  public isTrueReadOnly(): boolean {
    if (this.field.readOnly && !this.field.isExternal) {
      return !this.editableReadOnlyFields.includes(this.field.type);
    }
    return false;
  }

  public isElementEditable(): boolean {
    if (this.field.readOnly) {
      return this.editableReadOnlyFields.includes(this.field.type);
    }
    return this.field.canOpenAdbElement;
  }

  public showTitle(): boolean {
    if (this.field) {
      if (this.field.showfieldTitle && this.field.overrideTitle) {
        return true;
      }
    }
    return false;
  }

  public fieldHasValue(): boolean {
    if (this.fieldValue) {
      if (!this.fieldValue.elements || this.fieldValue.elements.length > 0) {
        return true;
      }
    }
    return false;
  }

  public isAdb(fieldValue) {
    if (fieldValue.elements) {
      return false;
    }
    if (Array.isArray(fieldValue) || this.field.isAdb) {
      return true;
    }
    return false;
  }

  public openEditField(field: DirectoryField) {
    let dialogRef = this.dialog.open(
      DirectoryFieldForRequestDialogComponent, {
        width: '800px',
        maxWidth: '100vw',
        panelClass: 'custom-dialog-field-request-container',
        data: {
          field: field,
          fieldValue: this.fieldValue,
          fieldTitle: this.field.overrideTitle,
          entity : this.entity,
          entityId: this.entityId,
        }
      }
    );

    dialogRef.afterClosed().subscribe((modification: ModificationsValue[]) => {
      // Send modified field to summary request dialog: either one or a group of fields (used by LinkedChoice types)
      if (modification && modification.length > 0 && modification[0]) {
        modification.forEach((modif) => {
          if (modif != null){
            this.directoryModificationsRequestService.updateModificationsRequest(modif);}
        })
      }
    });
  }

  public translationCompatibilityForOlderWidget(objectOrString, lang): {} {
    if (typeof objectOrString !== 'object') {
      let tempValue = objectOrString
      objectOrString = {};
      objectOrString[lang] = tempValue;
    }
    return objectOrString[lang];
  }

  public isFieldAnExternalOne(): boolean {
    return ExternalFieldTypes.find(x => x.toString() == this.field.type) != undefined;
  }

}
