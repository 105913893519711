<!--<div *ngIf="showField()">
  <h1>SHOW FIELD</h1>
</div>

<div *ngIf="isEditionModeActive()">
  <h1>isEditionModeActive</h1>
</div>

<div *ngIf="isTrueReadOnly()">
  <h1>isTrueReadOnly</h1>
</div>-->

<section class="directory-entity-field-wrapper" [class.edition-mode]="isEditionModeActive()"
         *ngIf="field && showField() && !(isEditionModeActive() && isTrueReadOnly())">
  <ng-container *ngIf="isEditionModeActive() || showTitle()">
    <h3 class="directory-entity-field-title">
      {{ translationCompatibilityForOlderWidget(field.overrideTitle, currentLang) }}
    </h3>
  </ng-container>

  <ng-container [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="'MultipleChoiceList'">
      <ng-container *ngTemplateOutlet="lm"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'MultipleChoiceListWithComment'">
      <ng-container *ngTemplateOutlet="lm"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'OrderedMultipleChoiceList'">
      <ng-container *ngTemplateOutlet="lm"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'UniqueChoiceList'">
      <ng-container *ngTemplateOutlet="lu"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'RevertedFromElement'">
      <ng-container *ngIf="!isEditionModeActive()">
        <ng-container *ngTemplateOutlet="lm"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'RevertedFromEntity'">
      <ng-container *ngIf="!isEditionModeActive()">
        <ng-container *ngTemplateOutlet="ell"></ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'MultipleEntityLink'">
      <ng-container *ngTemplateOutlet="ell"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'UniqueEntityLink'">
      <ng-container *ngTemplateOutlet="el"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Boolean'">
      <ng-container *ngTemplateOutlet="boolean"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'TripleState'">
      <ng-container *ngTemplateOutlet="ts"></ng-container>
    </ng-container>
    <!-- <ng-container *ngSwitchCase="'City'">
      <ng-container *ngTemplateOutlet="city"></ng-container>
    </ng-container> -->
    <ng-container *ngSwitchCase="'Url'">
      <ng-container *ngTemplateOutlet="url"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Email'">
      <ng-container *ngTemplateOutlet="email"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Image'">
      <ng-container *ngTemplateOutlet="image"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Date'">
      <ng-container *ngTemplateOutlet="date"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Numeric'">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Float'">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'LinkedChoice'">
      <ng-container *ngTemplateOutlet="lc"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'NonLocalizedText'">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'ExternalSource'">
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'ExternalSourceAssociatedDb'">
      <!--<ng-container *ngIf="!isEditionModeActive()">-->
        <ng-container *ngTemplateOutlet="lm"></ng-container>
      <!--</ng-container>-->
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </ng-container>
  </ng-container>

  <button mat-icon-button
      class="add-to-request-button util-el-surface"
      color="primary"
      (click)="openEditField(field)">
    <mat-icon>edit</mat-icon>
  </button>
</section>

<ng-template #lm>
  <ul class="directory-entity-field-value">
    <ng-container *ngIf="fieldValue && !isAdb(fieldValue)">
      <li *ngFor="let listElement of fieldValue" [ngClass]="'depth-'+listElement.depth">
        <ng-container *ngIf="listElement[CONSTANTS.name]">{{ listElement[CONSTANTS.name] }}</ng-container>
      </li>
    </ng-container>
    <ng-container *ngIf="fieldValue && isAdb(fieldValue)">
      <ng-container *ngIf="!isElementEditable() || isEditionModeActive()">
        <li *ngFor="let listElement of fieldValue" [ngClass]="'depth-'+listElement.depth">
          <ng-container *ngIf="listElement[CONSTANTS.name]">{{ listElement[CONSTANTS.name] }}</ng-container>
        </li>
      </ng-container>
      <ng-container *ngIf="isElementEditable() && !isEditionModeActive()">
        <li *ngFor="let listElement of fieldValue" [ngClass]="'depth-'+listElement.depth">
          <app-entity-adb-element [value]="listElement" [range]="field.range" [elementId]="listElement.element_id" [externalElement]="isFieldAnExternalOne()"></app-entity-adb-element>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</ng-template>

<ng-template #lu>
  <ng-container *ngIf="fieldValue && !isAdb(fieldValue)">
    <p class="directory-entity-field-value" *ngIf="fieldValue[CONSTANTS.name]">{{ fieldValue[CONSTANTS.name] }}</p>
  </ng-container>
  <ng-container *ngIf="fieldValue && isAdb(fieldValue)">
    <ng-container *ngIf="!isElementEditable() || isEditionModeActive()">
      <p class="directory-entity-field-value" *ngIf="fieldValue[CONSTANTS.name]">{{ fieldValue[CONSTANTS.name] }}</p>
    </ng-container>
    <ng-container *ngIf="isElementEditable() && !isEditionModeActive()">
      <app-entity-adb-element [value]="fieldValue" [range]="field.range" [elementId]="fieldValue.element_id" [externalElement]="isFieldAnExternalOne()"></app-entity-adb-element>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #ell>
  <ul *ngIf="fieldValue" class="directory-entity-field-value">
    <li *ngFor="let entityLink of fieldValue">
      <app-entity-link [id]="entityLink.element_id"></app-entity-link>
    </li>
  </ul>
</ng-template>

<ng-template #el>
  <p *ngIf="fieldValue" class="directory-entity-field-value">
    <app-entity-link [id]="fieldValue"></app-entity-link>
  </p>
</ng-template>

<ng-template #boolean>
  <ng-container *ngIf="fieldValue">
    <p class="directory-entity-field-value">{{ 'fields.boolean.' + fieldValue | translate }}</p>
  </ng-container>
</ng-template>

<ng-template #ts>
  <ng-container *ngIf="fieldValue">
    <p class="directory-entity-field-value">{{ 'fields.triplestate.' + fieldValue | translate }}</p>
  </ng-container>
</ng-template>

<!-- <ng-template #city>
  <p class="directory-entity-field-value" *ngIf="fieldValue">
    <ng-container *ngIf="fieldValue.city">{{ fieldValue.city }}</ng-container>
    <ng-container *ngIf="fieldValue.city && fieldValue.zip_code">, </ng-container>
    <ng-container *ngIf="fieldValue.zip_code">{{ fieldValue.zip_code }}</ng-container>
  </p>
</ng-template> -->

<ng-template #url>
  <ng-container *ngIf="fieldValue">
    <a mat-flat-button
        *ngIf="field.displayLinkAsButton && field.overrideTitle[currentLang]"
        [href]="fieldValue" target="_blank"
        appCustomizedFontColor appCustomizedHoverColor class="directory-entity-url-value">
      <span>{{ field.overrideTitle[currentLang] }}</span>
      <mat-icon>link</mat-icon>
    </a>
    <a *ngIf="!field.displayLinkAsButton || !field.overrideTitle[currentLang]"
        [href]="fieldValue" target="_blank"
        class="directory-entity-field-value">
      {{ fieldValue }}
    </a>
  </ng-container>
</ng-template>

<ng-template #email>
  <a [href]="'mailto:' + fieldValue" class="directory-entity-field-value">{{ fieldValue }}</a>
</ng-template>

<ng-template #image>
  <figure>
    <img src="{{ fieldValue }}" class="directory-entity-field-value"  alt="" *ngIf="fieldValue"/>
  </figure>
</ng-template>

<ng-template #date>
  <p class="directory-entity-field-value" *ngIf="fieldValue">{{ fieldValue | date: 'd/M/y' }}</p>
</ng-template>

<ng-template #lc>
  <p class="directory-entity-field-value" *ngIf="fieldValue">{{ fieldValue[CONSTANTS.name] }}</p>
  <!-- <app-choice-list-show [value]="fieldList"></app-choice-list-show> -->
</ng-template>

<!-- Default -->
<ng-template #text>
  <p class="directory-entity-field-value" *ngIf="fieldValue">{{ fieldValue }}</p>
</ng-template>
