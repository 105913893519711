<ng-container *ngFor="let fieldsgroup of fieldsGroups | orderByImpure: 'order'; trackBy: itemsTrackedBy">
    <div class="fieldsgroup-card">
        <p class="button1 high mt-0 mb-4" *ngIf="displayGroupsTitle">
            {{ fieldsgroup.title[currentLang] }}
        </p>
        <ng-container *ngFor="let field of fieldsgroup.fields | orderByImpure: 'order'">
            <ng-container *ngIf="field">
                <div class="add-request-field" [class.hidden]="field.hidden">
                    <div class="field-infos">
                        <p class="field-title button1 medium">
                            {{ field.overrideTitle[currentLang] }}
                            <span *ngIf="field.required" class="required-indicator">*</span>
                        </p>
                        <p class="field-comment caption1">
                            {{ field.overrideDescription[currentLang] }}
                        </p>
                    </div>
                    <ng-container [ngSwitch]="field.type">
                        <!-- List -->
                        <ng-container *ngSwitchCase="FieldType.MultipleChoiceList">
                            <app-add-field-list 
                                [field]="field" 
                                [multiple]="true" 
                                [formGroup]="formGroup" 
                                [isSubrequest]="isSubrequest" 
                                [config]="config"></app-add-field-list>
                        </ng-container>
                        <ng-container *ngSwitchCase="FieldType.MultipleChoiceListWithComment">
                            <app-add-field-list 
                                [field]="field" 
                                [multiple]="true" 
                                [formGroup]="formGroup" 
                                [isSubrequest]="isSubrequest" 
                                [config]="config"></app-add-field-list>
                        </ng-container>
                        <ng-container *ngSwitchCase="FieldType.OrderedMultipleChoiceList">
                            <app-add-field-list 
                                [field]="field" 
                                [multiple]="true" 
                                [formGroup]="formGroup" 
                                [isSubrequest]="isSubrequest" 
                                [config]="config"></app-add-field-list>
                        </ng-container>
                        <ng-container *ngSwitchCase="FieldType.UniqueChoiceList">
                            <app-add-field-list 
                                [field]="field" 
                                [formGroup]="formGroup" 
                                [isSubrequest]="isSubrequest" 
                                [config]="config"></app-add-field-list>
                        </ng-container>
                        <ng-container *ngSwitchCase="FieldType.ExternalSourceAssociatedDb">
                            <app-add-field-list 
                                [field]="field" 
                                [multiple]="true" 
                                [formGroup]="formGroup" 
                                [isSubrequest]="isSubrequest" 
                                [config]="config"></app-add-field-list>
                        </ng-container>

                        <!-- Boolean & variant -->
                        <ng-container *ngSwitchCase="FieldType.Boolean">
                            <app-add-field-boolean [field]="field" [formGroup]="formGroup"></app-add-field-boolean>
                        </ng-container>
                        <ng-container *ngSwitchCase="FieldType.TripleState">
                            <app-add-field-boolean [field]="field" tripleState="true" [formGroup]="formGroup"></app-add-field-boolean>
                        </ng-container>

                        <!-- Date -->
                        <ng-container *ngSwitchCase="FieldType.Date">
                            <app-add-field-date [field]="field" [formGroup]="formGroup"></app-add-field-date>
                        </ng-container>

                        <!-- Linked Choice -->
                        <ng-container *ngSwitchCase="FieldType.LinkedChoice">
                            <div *ngFor="let linked of linkedChoiceValue">
                                <ng-container *ngIf="linked.field.id === field.id">
                                    <app-add-field-linked-choice
                                        [list]="linked.list"
                                        [field]="linked.field"
                                        [value]="linked.field_value"
                                        [parent]="linked.parent_value"
                                        (valueToShare)="crossOffValue($event)"></app-add-field-linked-choice>
                                    <input matInput
                                        class="hidden"
                                        [formControlName]="linked.field.code" readonly disabled>
                                </ng-container>
                            </div>
                        </ng-container>

                        <!-- Upload -->
                        <ng-container *ngSwitchCase="FieldType.Image">
                            <app-add-field-image [field]="field" [formGroup]="formGroup"></app-add-field-image>
                        </ng-container>

                        <!-- Numbers -->
                        <ng-container *ngSwitchCase="FieldType.Numeric">
                            <app-add-field-text [field]="field" type="text" subtype="number" [formGroup]="formGroup"></app-add-field-text>
                        </ng-container>
                        <ng-container *ngSwitchCase="FieldType.Float">
                            <app-add-field-text [field]="field" type="text" subtype="number" float="true" [formGroup]="formGroup"></app-add-field-text>
                        </ng-container>

                        <!-- Entity -->
                        <ng-container *ngSwitchCase="FieldType.UniqueEntityLink">
                            <app-edit-field-entity-link 
                                [field]="field" 
                                type="text" 
                                [formGroup]="formGroup"
                                [isSubrequest]="isSubrequest" 
                                [config]="config"></app-edit-field-entity-link>
                        </ng-container>
                        <ng-container *ngSwitchCase="FieldType.MultipleEntityLink">
                            <app-edit-field-entity-link 
                                [field]="field" 
                                type="text" 
                                [multiple]="true" 
                                [formGroup]="formGroup"
                                [isSubrequest]="isSubrequest" 
                                [config]="config"></app-edit-field-entity-link>
                        </ng-container>

                        <!-- Text -->
                        <ng-container *ngSwitchCase="FieldType.URL">
                            <app-add-field-text [field]="field" type="url" [formGroup]="formGroup"></app-add-field-text>
                        </ng-container>
                        <ng-container *ngSwitchCase="FieldType.Email">
                            <app-add-field-text [field]="field" type="email" [formGroup]="formGroup"></app-add-field-text>
                        </ng-container>
                        <ng-container *ngSwitchCase="FieldType.City">
                            <app-add-field-text [field]="field" [formGroup]="formGroup"></app-add-field-text>
                        </ng-container>
                        <ng-container *ngSwitchCase="FieldType.NonLocText">
                            <app-add-field-text [field]="field" type="text" [formGroup]="formGroup"></app-add-field-text>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <app-add-field-text [field]="field" textarea="true" [formGroup]="formGroup"></app-add-field-text>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>